import React, { useEffect, useState, } from "react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useMediaPredicate } from "react-media-hook"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import "../../../graphql/fragments"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Cover } from "../../../components/cover/cover"
import { CoverFeatured } from "../../../components/cover-featured/cover-featured"
import { HeadingBasic } from "../../../components/heading/heading"

import {
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../../../components/utils/render-functions"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const SrCaseStudy = ({ location }) => {
  const [allSrOffset, setAllSrOffset] = useState(1)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgBannerSearch: file(relativePath: { eq: "imgBannerSearch1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability1: file(
        relativePath: { eq: "img-sustainbility-social.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability2: file(
        relativePath: { eq: "img-sustainbility-economy.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgSustainability3: file(
        relativePath: { eq: "img-sustainbility-environment.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "/sustainability/sr-case-study") {
          # id
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...AcfIconListBlock
            ...CoreEmbedYoutube
            ...CoreShortcodeBlock
            ...CoreButtonBlock
            ...CoreListBlock
          }
          childPages {
            nodes {
              title
              slug
              excerpt(format: RAW)
              featuredImage {
                sourceUrl
                title
                caption
              }
            }
          }
          seo {
            title
            twitterDescription
            metaDesc
            opengraphDescription
          }
          translation(language: ID) {
            id
            title
            slug
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreCoverBlock
              ...AcfGalleryBlock
              ...CoreGroupBlock
              ...AcfIconListBlock
              ...CoreEmbedYoutube
              ...CoreShortcodeBlock
              ...CoreButtonBlock
              ...CoreListBlock
            }
            childPages {
              nodes {
                title
                slug
                excerpt(format: RAW)
                featuredImage {
                  sourceUrl
                  title
                  caption
                }
              }
            }
            seo {
              title
              twitterDescription
              metaDesc
              opengraphDescription
            }
          }
        }
      }
    }
  `)

  const wordPress = data.wordPress.pageBy.translation?.blocks || data.wordPress.pageBy.blocks
  const seo = data.wordPress.pageBy.translation?.seo || data.wordPress.pageBy.seo
  const cover =
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
    return (
      <div className="d-flex justify-content-left">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  }

  const allSrNextPage = () => {
    if (wordPress[allSrOffset * 9] !== undefined) {
      setAllSrOffset(allSrOffset + 1)
    }
  }
  const allSrPrevPage = () => {
    if (allSrOffset >= 1) {
      setAllSrOffset(allSrOffset - 1)
    }
  }

  useEffect(() => {
    if (location.pathname !== null) {
      let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")

      if (resizeScreen) {
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      } else {
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
    }
  }, [])

  return (
    <Layout currentLocation={location.pathname} lang="EN">
      <SEO title={seo.title} description={seo.metaDesc} />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <section className="overflow-x-hidden">
        <div className="row py-main">
          <div className="col-md-4">
            <CoverMain
              h1={data.wordPress.pageBy.title || "SR Case Study"}
              img={
                cover ? cover.attributes.url : data.imgCoverMain.childImageSharp.fluid
              }
              alt="SR Case Study"
              className="cover-md mt-0"
            >
              <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
            </CoverMain>
          </div>
          <div className="col-md-8 list-sr-wrapper">
            {wordPress.map((item, i) => {
              if (i > allSrOffset * 9 - 9 && i < allSrOffset * 9 + 1) {
                switch (item.name) {
                  case "core/list":
                    return (
                      <div className="heading heading-basic mb-0">
                        <ul>
                          <ReactMarkdown
                            source={item.attributes.values}
                            escapeHtml={false}
                          />
                        </ul>
                      </div>
                    )
                  case "core/heading":
                    return (
                      <div key={`heading-${i}`} className="container">
                        <div className={i === 0 ? "py-main" : "pt-main"}>
                          <HeadingBasic h2={item.attributes.content} />
                        </div>
                      </div>
                    )
                  case "core-embed/youtube":
                    return (
                      <div className="py-main">
                        <Cover
                          variant="basic"
                          theme="dark"
                          img={null}
                          imgHeight="h-400px h-md-500px w-100"
                          // imgOverlay="40"
                          className="cover cover-full-top"
                          // type="video-popup"
                          videoSrc={item.attributes && item.attributes.url}
                          type={{ name: "core-embed/youtube" }}
                          audio={false}
                        />
                      </div>
                    )
                  case "core/buttons":
                    if (
                      item.innerBlocks[0].attributes.url &&
                      item.innerBlocks[0].attributes.url.split(".").length > 1
                    ) {
                      return (
                        <div key={`button=${i}`} className="container">
                          {/* <div className={i === 0 ? "py-main" : "pb-main"}> */}
                          <div className="container-fluid pl-2-point-5">
                            <HeadingBasic>
                              <a
                                href={`/id${item.innerBlocks[0].attributes.url}`}
                                target="_blank"
                                className="btn btn-link fx-underline"
                                rel="noopener noreferrer"
                                key={`${item.innerBlocks[0].attributes.url}-${i}`}
                              >
                                {item.innerBlocks[0].attributes.text} &nbsp;
                                <i className="far fa-external-link"></i>
                              </a>
                            </HeadingBasic>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div key={`button-${i}`} className="container">
                          <div className="container-fluid pl-2-point-5">
                            <Link
                              key={`${item.innerBlocks[0].attributes.url}-${i}`}
                              to={`/id${item.innerBlocks[0].attributes.url}`}
                              className="btn btn-link fx-underline"
                            >
                              {item.innerBlocks[0].attributes.text}
                            </Link>
                          </div>
                        </div>
                      )
                    }
                  case "core/paragraph":
                    return (
                      <div key={`headingbasic-${i}`} className="container-fluid pl-1-point-5">
                        <div className="container container-md">
                          <HeadingBasic text={[item.attributes.content.replace(/^(.{100}[^\s]*).*/, "$1...")]} />
                        </div>
                      </div>
                    )
                  case "core/group":
                    return (
                      <div key={`${item.name}-${i}`} className="py-main">
                        <WpGroup data={item} />
                      </div>
                    )
                  case "acf/iconlist":
                    return (
                      <div
                        key={`iconlist-${i}`}
                        className={i === 0 ? "container py-main" : "container pb-main"}
                      >
                        <WpIconlist data={item} />
                      </div>
                    )
                  case "acf/labelvalues":
                    return (
                      <div key={`${item.name}-${i}`} className="pb-main">
                        <Stats data={item} />
                      </div>
                    )
                  case "core/cover":
                    if (i !== 0) {
                      return (
                        <div key={`featured-${i}`} className="pb-main">
                          <CoverFeatured
                            label="Featured News"
                            img={
                              item.attributes.url || data.featured.childImageSharp.fluid
                            }
                            title={
                              item.innerBlocks[0] !== undefined &&
                              item.innerBlocks[0].attributes.content
                            }
                            text={
                              item.innerBlocks[1] !== undefined &&
                              item.innerBlocks[1].attributes.content
                            }
                            btnText={
                              item.innerBlocks[2] !== undefined &&
                              item.innerBlocks[2].innerBlocks[0].attributes.text
                            }
                            btnLink={`/id/development/office/${(item.innerBlocks[2] !==
                              undefined &&
                              item.innerBlocks[2].innerBlocks[0].attributes.url) ||
                              ""}`}
                          />
                        </div>
                      )
                    }
                    return <div key={`empty-${i}`}></div>
                  case "acf/acfgallery":
                    return (
                      <div key={`gallery-${i}`} className="container">
                        <Carousel data={item} />
                      </div>
                    )
                  default:
                    return <div key={`default-${i}`}>{item.name}</div>
                }
              }
            })}
            <div className="col-md-12">
              <Pagination
                hasPreviousPage={allSrOffset === 1}
                hasNextPage={
                  wordPress[allSrOffset * 9 + 1] === undefined
                }
                prevPage={allSrPrevPage}
                nextPage={allSrNextPage}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SrCaseStudy
